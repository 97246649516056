/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.status-indicator {
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin: 4px;
}

.status-indicator.small-status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin: 8px;
}

.status-indicator.big-status-indicator {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin: 0;
}

.status-indicator.red {
  position: relative;
  z-index: 2;
  background-color: #D02F3A;
}

.status-indicator.yellow {
  background-color: #eabe06;
}

.status-indicator.green {
  background-color: #00aa00;
}

.status-indicator.orange {
  background-color: #ed7d20;
}

.status-indicator.gray {
  background-color: #b4b4b4;
}
