{
  "account": [],
  "api_documentation": [],
  "background_tasks": [
    {
      "link": "/analyzing-source-code/background-tasks/",
      "text": "About Background Tasks"
    }
  ],
  "code": [],
  "coding_rules": [
    {
      "link": "/instance-administration/quality-profiles/",
      "text": "Quality Profiles"
    }
  ],
  "component_measures": [
    {
      "link": "/user-guide/clean-as-you-code/",
      "text": "Clean as You Code"
    },
    {
      "link": "/user-guide/metric-definitions/",
      "text": "Metric Definitions"
    }
  ],
  "global_permissions": [],
  "issues": [],
  "marketplace": [],
  "overview": [
    {
      "link": "/analyzing-source-code/pull-request-analysis",
      "text": "Enable Pull Request Decoration"
    },
    {
      "link": "/analyzing-source-code/ci-integration/overview/",
      "text": "Set up CI analysis"
    },
    {
      "link": "/user-guide/clean-as-you-code/",
      "text": "Clean as You Code"
    },
    {
      "link": "/user-guide/sonarlint-connected-mode/",
      "text": "SonarLint Connected Mode"
    }
  ],
  "permission_templates": [],
  "profiles": [
    {
      "link": "/instance-administration/quality-profiles/",
      "text": "Quality Profiles"
    }
  ],
  "project_activity": [],
  "project_baseline": [
    {
      "link": "/project-administration/defining-new-code/",
      "text": "Defining New Code"
    }
  ],
  "project_quality_gate": [
    {
      "link": "/user-guide/clean-as-you-code/",
      "text": "Clean as You Code"
    }
  ],
  "project_quality_profiles": [
    {
      "link": "/instance-administration/quality-profiles/",
      "text": "About Quality Profiles"
    }
  ],
  "projects_management": [],
  "projects": [],
  "pull_requests": [
    {
      "link": "/user-guide/clean-as-you-code/",
      "text": "Clean as You Code"
    },
    {
      "link": "/analyzing-source-code/pull-request-analysis",
      "text": "Analyzing Pull Requests"
    },
    {
      "link": "/user-guide/sonarlint-connected-mode/",
      "text": "SonarLint connected mode"
    }
  ],
  "quality_gates": [
    {
      "link": "/user-guide/clean-as-you-code/",
      "text": "Clean as You Code"
    }
  ],
  "quality_profiles": [
    {
      "link": "/instance-administration/quality-profiles/",
      "text": "Quality Profiles"
    }
  ],
  "security_reports": [
    {
      "link": "/user-guide/security-reports/",
      "text": "About Security Reports"
    }
  ],
  "settings": [],
  "system_info": [],
  "user_groups": [],
  "users": [],
  "webhooks": [
    {
      "link": "/project-administration/webhooks/",
      "text": "About Webhooks"
    }
  ]
}
