/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */

.overview {
  animation: fadeIn 0.5s forwards;
}

/*
 * Measures
 */

.overview-measures-row {
  min-height: 105px;
  box-sizing: border-box;
}

.overview-measures-row + .overview-measures-row {
  border-top: 1px solid #e6e6e6;
}

.overview-measures-value {
  line-height: 1;
  font-size: 36px;
  white-space: nowrap;
}

.overview-measures-empty-value {
  height: 1px;
  width: 16px;
  background: #333;
}

.overview-measures-aside {
  flex-basis: 200px;
  box-sizing: border-box;
}

.overview-measures-tab {
  width: 128px;
}

.overview-measures-emphasis {
  background: #f5f9fc;
}

.overview-quality-gate-conditions-list {
  background-color: white;
}

.overview-quality-gate-condition,
.overview-quality-gate-condition:hover {
  display: block;
  color: #333;
  border: none;
  transition: background-color 0.3s ease;
}

.overview-quality-gate-condition:hover {
  background-color: #ecf6fe;
}

/*
 * Animations
 */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*
 * PRs and SLBs
 */

.pr-overview {
  max-width: 1020px;
  margin: 0 auto;
}

.pr-overview.has-conditions {
  max-width: 1260px;
}

.pr-overview .overview-quality-gate-condition:first-of-type {
  margin-top: 0;
}

.pr-overview .overview-quality-gate-condition {
  margin-top: 12px;
  background-color: #fff;
  border-left: 5px solid;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.pr-overview .overview-quality-gate-condition-error {
  border-color: #D02F3A;
}

.pr-overview .overview-quality-gate-condition-warn {
  border-color: #ed7d20;
}

.pr-pverview .overview-measures-row {
  min-height: 85px;
}

.pr-overview .overview-measures-aside {
  flex-basis: 270px;
}

@media (max-width: 1200px) {
  .pr-overview .overview-measures-aside {
    flex-basis: 220px;
  }
}

/*
 * ACTIVITY
 */

.overview-panel .activity-graph-legends {
  justify-content: right !important;
  margin-top: -38px;
}

.overview-analysis {
  color: #656565;
}

.overview-analysis + .overview-analysis {
  margin-top: 16px;
}

.overview-activity-events {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.overview-analysis-event {
  display: inline-block;
}

.overview-analysis-event.badge {
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overview-analysis-event + .overview-analysis-event {
  margin-top: 4px;
}
