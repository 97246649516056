/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.permissions-table .permission-column.selected {
  background-color: #d9edf7;
}

.permissions-table .permission-column-inner {
  width: 90px;
}

.permissions-table .divider {
  background: #fff;
  padding: 16px 0;
}
.permissions-table .divider::after {
  display: block;
  content: '';
  background: #e6e6e6;
  height: 1px;
  width: 100%;
}

.permissions-table td:first-of-type {
  max-width: 320px;
}
