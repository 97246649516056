/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
import * as React from 'react';
import { useState } from 'react';
import { ButtonIcon } from '../../../components/controls/buttons';
import AlertErrorIcon from '../../../components/icons/AlertErrorIcon';
import AlertSuccessIcon from '../../../components/icons/AlertSuccessIcon';
import BulletListIcon from '../../../components/icons/BulletListIcon';
import DateTimeFormatter from '../../../components/intl/DateTimeFormatter';
import { translate, translateWithParameters } from '../../../helpers/l10n';
import { WebhookResponse } from '../../../types/webhook';
import LatestDeliveryForm from './LatestDeliveryForm';

interface Props {
  webhook: WebhookResponse;
}

export default function WebhookItemLatestDelivery({ webhook }: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  if (!webhook.latestDelivery) {
    return <span>{translate('webhooks.last_execution.none')}</span>;
  }

  return (
    <>
      {webhook.latestDelivery.success ? (
        <AlertSuccessIcon className="text-text-top" />
      ) : (
        <AlertErrorIcon className="text-text-top" />
      )}
      <span className="spacer-left display-inline-flex-center">
        <DateTimeFormatter date={webhook.latestDelivery.at} />
        <ButtonIcon
          aria-label={translateWithParameters('webhooks.last_execution.open_for_x', webhook.name)}
          className="button-small little-spacer-left"
          onClick={() => setModalOpen(true)}
        >
          <BulletListIcon />
        </ButtonIcon>
      </span>

      {modalOpen && (
        <LatestDeliveryForm
          delivery={webhook.latestDelivery}
          onClose={() => setModalOpen(false)}
          webhook={webhook}
        />
      )}
    </>
  );
}
