/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.rating {
  display: inline-flex;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

.rating-muted {
  background-color: #b4b4b4 !important;
  color: #FFFFFF !important;
}

a > .rating-A,
.rating-A {
  color: rgba(0, 0, 0, 0.75);
  background-color: #6CD46C;
}

a > .rating-B,
.rating-B {
  color: rgba(0, 0, 0, 0.75);
  background-color: #C6E056;
}

a > .rating-C,
.rating-C {
  color: rgba(0, 0, 0, 0.75);
  background-color: #F4D348;
}

a > .rating-D,
.rating-D {
  color: rgba(0, 0, 0, 0.75);
  background-color: #F69D53;
}

a > .rating-E,
.rating-E {
  color: rgba(0, 0, 0, 0.75);
  background-color: #F0878E;
}
