/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */

.rdp {
  --rdp-cell-size: 30px;
  --rdp-caption-font-size: 13px;
  /* Ensures the month/year dropdowns do not move on click, but rdp outline is not shown */
  --rdp-outline: 2px solid transparent;
  --rdp-outline-selected: 2px solid transparent;
}

.rdp-day_selected {
  background-color: #4b9fd5;
}

.rdp-day_selected:hover {
  background-color: #4b9fd5;
}

.date-input-control {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.date-input-control-input {
  width: 130px;
  padding-left: 24px !important;
  cursor: pointer;
}

.date-input-control-input.is-filled {
  padding-right: 16px !important;
}

.date-input-control-icon {
  position: absolute;
  top: 4px;
  left: 4px;
}

.date-input-control-icon path {
  fill: #666666;
  opacity: 0.9;
}

.date-input-control-input:focus + .date-input-control-icon path {
  fill: #0271B9;
}

.date-input-control-reset {
  position: absolute;
  top: 4px;
  right: 4px;
  border: none;
}

.date-input-calendar {
  position: absolute;
  z-index: 7500;
  top: 100%;
  left: 0;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.date-input-calendar.align-right {
  left: initial;
  right: 0;
}
