/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
button.search-navigator-facet {
  text-align: start;
}

.search-navigator-facet .leak-box {
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  margin-top: -1px;
  margin-right: -7px;
  border-radius: 2px;
  box-sizing: border-box;
}

.search-navigator-facet:hover .leak-box,
.search-navigator-facet.active .leak-box {
  height: 22px;
  margin-top: 0;
  margin-right: -6px;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.search-navigator-facet.active .leak-box {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.measure-details-treemap-legend.color-box-legend {
  margin-right: 0;
}

.measure-details-viewer .issue-list {
  /* no math, just a good guess */
  min-width: 600px;
  width: 800px;
}

@media (max-width: 1320px) {
  .measure-details-viewer .issue-list {
    width: calc(60vw - 80px);
  }
}
